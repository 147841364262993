import type { SerializeFrom } from "@remix-run/server-runtime";

import {
  STRIPE_CREATE_CHECKOUT_PATH,
  STRIPE_CREATE_PAYMENT_INTENT_PATH,
} from "./constants.client";
import type {
  createCheckoutSessionSchema,
  resourceSripeCreateCheckout,
} from "./resources/create-checkout-session";
import type {
  createConfirmIntentSchema,
  resourceSripeCreateConfirmIntent,
} from "./resources/create-confirm-intent";

export const fetchStripePaymentIntent = async (
  stripePaymentMethodId: string,
  getUrl: (path: typeof STRIPE_CREATE_PAYMENT_INTENT_PATH) => string = () =>
    STRIPE_CREATE_PAYMENT_INTENT_PATH,
): Promise<SerializeFrom<typeof resourceSripeCreateConfirmIntent>> => {
  const response = await fetch(getUrl(STRIPE_CREATE_PAYMENT_INTENT_PATH), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      stripePaymentMethodId,
    } satisfies Zod.infer<typeof createConfirmIntentSchema>),
  });
  return await response.json<
    SerializeFrom<typeof resourceSripeCreateConfirmIntent>
  >();
};

export const fetchStripeCreateCheckout = async (
  getUrl: (path: typeof STRIPE_CREATE_CHECKOUT_PATH) => string = () =>
    STRIPE_CREATE_CHECKOUT_PATH,
): Promise<SerializeFrom<typeof resourceSripeCreateCheckout>> => {
  const response = await fetch(getUrl(STRIPE_CREATE_CHECKOUT_PATH), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      {} satisfies Zod.infer<typeof createCheckoutSessionSchema>,
    ),
  });
  return await response.json<
    SerializeFrom<typeof resourceSripeCreateCheckout>
  >();
};
